import React from "react"

import Layout from "../components/layout"
import Products from "../components/products/mapy.js"
import SEO from "../components/seo"

const PlakatyPage = () => (
  <Layout>
    <SEO title="Mapy" />

    <Products />
  </Layout>
)

export default PlakatyPage
